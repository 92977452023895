import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { lessThan } from '../../utils/mediaQuery';

export const Container = styled.section`
  margin: ${rem(128, 22)} 0;
  padding: 0 24px;

  ${lessThan(480)} {
    margin: ${rem(64, 16)} 0;
  }

  ${({ $bgColorAlt }) =>
    $bgColorAlt &&
    css`
      margin: 0;
      padding: ${rem(88, 22)} 24px ${rem(128, 22)};

      background-color: ${({ theme }) => theme.colors.veryLightPink};

      ${lessThan(480)} {
        padding-top: ${rem(64, 16)};
        padding-bottom: ${rem(64, 16)};
      }
    `}
`;

export const Wrapper = styled.div`
  max-width: 1408px;

  margin: auto;
`;
