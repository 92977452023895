// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Container, Wrapper } from './SectionContainer.styles';

const SectionContainer = ({ children, bgColorAlt, ...rest }) => (
  <Container $bgColorAlt={bgColorAlt} {...rest}>
    <Wrapper>{children}</Wrapper>
  </Container>
);

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  bgColorAlt: PropTypes.bool,
};

SectionContainer.defaultProps = {
  bgColorAlt: false,
};

export default SectionContainer;
