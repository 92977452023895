// vendors
import styled from 'styled-components';
import Img from 'gatsby-image';
import { rem } from 'polished';

// utils
import { greaterThan } from '@utils/mediaQuery';

// styles
import { h2Style } from '@styles/global';
import { sideBreakpoint } from '@components/SideBarLayout/SideBarLayout.styles';

export const layoutBreakpoint = sideBreakpoint + 2 * 24;

export const List = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;

  ${({ theme }) => theme.layouts.stack(rem(60, 16))}

  ${greaterThan(480)} {
    ${({ theme }) => theme.layouts.stack(rem(150, 22))}
  }
`;

export const ListItem = styled.li``;

export const PictureWrapper = styled.div``;

export const Picture = styled(Img)``;

export const TextWrapper = styled.div``;

export const Title = styled.h3`
  ${h2Style}

  margin: 0 0 ${rem(14, 22)};
`;

export const CTAContainer = styled.div`
  margin-top: ${rem(30, 16)};

  ${greaterThan(480)} {
    margin-top: ${rem(60, 22)};
  }

  > * {
    width: 100%;
    max-width: 408px;
  }
`;
