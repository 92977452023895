/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { hideVisually } from 'polished';

// utils
import generatePath from '@utils/generatePath';
import { greaterThan, greaterThanCondition } from '@utils/mediaQuery';

// images
import IconArrow from '@images/IconArrow';

// components
import BlockContent from '@components/BlockContent';
import Button from '@components/Button';
import SectionContainer from '@components/SectionContainer';
import SideBarLayout from '@components/SideBarLayout';

// styles
import {
  layoutBreakpoint,
  List,
  ListItem,
  PictureWrapper,
  Picture,
  TextWrapper,
  CTAContainer,
  Title,
} from './ProjectsSection.styles';

const messages = defineMessages({
  projectsSectionId: {
    defaultMessage: 'some-projects',
    description: 'Id of projects section in the projects page',
  },
});

const ProjectsSection = ({ projects }) => {
  const { formatMessage } = useIntl();

  const nodes = projects.map((project) => {
    const {
      _type: type,
      localization: { locale },
      slug: { current: slug },
    } = project;

    const path = generatePath(slug, locale, type);

    const {
      asset: { desktop, mobile },
    } = project.pictures[0];

    return {
      location: `${project.city}, ${project.state}`,
      rawShortDescription: project._rawShortDescription,
      sources: [
        {
          ...mobile,
          sizes: '100vw',
        },
        {
          ...desktop,
          media: greaterThanCondition(layoutBreakpoint),
        },
      ],
      to: path,
      ...project,
    };
  });

  return (
    <SectionContainer id={formatMessage(messages.projectsSectionId)}>
      <h2 css={hideVisually}>
        <FormattedMessage
          defaultMessage='Nos réalisations'
          description='Heading of projects section in the projects page'
        />
      </h2>

      <List>
        {nodes.map((node, index) => (
          <ListItem key={node.id}>
            <SideBarLayout
              spaced
              css={`
                overflow: inherit;
              `}
            >
              <PictureWrapper>
                {node.sources.length > 0 && (
                  <Picture fluid={node.sources} role='presentation' alt='' />
                )}
              </PictureWrapper>

              <TextWrapper
                css={`
                  ${greaterThan(layoutBreakpoint)} {
                    order: ${index % 2 === 0 ? '0' : '-1'};
                  }
                `}
              >
                <Title>{node.title}</Title>

                <p
                  css={`
                    margin-top: 0;
                  `}
                >
                  {node.location}
                </p>

                <BlockContent blocks={node.rawShortDescription} />

                <CTAContainer>
                  <Button
                    to={node.to}
                    renderIcon={<IconArrow />}
                    tag='link'
                    outlined
                    primary
                  >
                    <FormattedMessage
                      defaultMessage='Détails'
                      description='CTA button to go to the project details page from the projects page'
                    />
                  </Button>
                </CTAContainer>
              </TextWrapper>
            </SideBarLayout>
          </ListItem>
        ))}
      </List>
    </SectionContainer>
  );
};
ProjectsSection.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      _rawShortDescription: PropTypes.arrayOf(PropTypes.shape({})),
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          asset: PropTypes.shape({
            mobile: PropTypes.shape({
              aspectRatio: PropTypes.number.isRequired,
              src: PropTypes.string.isRequired,
              srcSet: PropTypes.string.isRequired,
              srcSetWebp: PropTypes.string.isRequired,
              srcWebp: PropTypes.string.isRequired,
            }).isRequired,
            desktop: PropTypes.shape({
              aspectRatio: PropTypes.number.isRequired,
              src: PropTypes.string.isRequired,
              srcSet: PropTypes.string.isRequired,
              srcSetWebp: PropTypes.string.isRequired,
              srcWebp: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ).isRequired,
    }).isRequired
  ),
};

ProjectsSection.defaultProps = {
  projects: [],
};

export default ProjectsSection;
